<template>
  <footer :class="$style.footer">
    <div :class="$style.footer__container">
      <p :class="$style.footer__links">
        <a href="/" target="_blank" rel="noopener noreferrer">
          © 2023 Perfection·DEV
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          :href="$externalLinks.privacyPolicy"
        >
          {{ $t('footer.privacyPolicy') }}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          :href="$externalLinks.terms"
        >
          {{ $t('footer.terms') }}
        </a>
      </p>
      <div :class="$style.socials">
        <a
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
          :href="$externalLinks.linkedIn"
          :class="$style.socials__item"
        >
          <svg viewBox="0 0 24 25">
            <path
              d="M22.2234 0.199951H1.77187C0.792187 0.199951 0 0.973389 0 1.92964V22.4656C0 23.4218 0.792187 24.2 1.77187 24.2H22.2234C23.2031 24.2 24 23.4218 24 22.4703V1.92964C24 0.973389 23.2031 0.199951 22.2234 0.199951ZM7.12031 20.6515H3.55781V9.19526H7.12031V20.6515ZM5.33906 7.63433C4.19531 7.63433 3.27188 6.71089 3.27188 5.57183C3.27188 4.43276 4.19531 3.50933 5.33906 3.50933C6.47813 3.50933 7.40156 4.43276 7.40156 5.57183C7.40156 6.7062 6.47813 7.63433 5.33906 7.63433ZM20.4516 20.6515H16.8937V15.0828C16.8937 13.7562 16.8703 12.0453 15.0422 12.0453C13.1906 12.0453 12.9094 13.4937 12.9094 14.989V20.6515H9.35625V9.19526H12.7687V10.7609H12.8156C13.2891 9.86089 14.4516 8.90933 16.1813 8.90933C19.7859 8.90933 20.4516 11.2812 20.4516 14.3656V20.6515Z"
            />
          </svg>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
          :href="$externalLinks.twitter"
          :class="$style.socials__item"
        >
          <svg viewBox="0 0 24 25">
            <path
              d="M7.55016 21.9499C16.6045 21.9499 21.5583 14.4467 21.5583 7.94181C21.5583 7.73087 21.5536 7.51525 21.5442 7.30431C22.5079 6.60741 23.3395 5.74421 24 4.75525C23.1025 5.15455 22.1496 5.41533 21.1739 5.52869C22.2013 4.91286 22.9705 3.94542 23.3391 2.80572C22.3726 3.37851 21.3156 3.78256 20.2134 4.00056C19.4708 3.21151 18.489 2.68907 17.4197 2.514C16.3504 2.33894 15.2532 2.521 14.2977 3.03205C13.3423 3.54309 12.5818 4.35466 12.1338 5.34126C11.6859 6.32787 11.5754 7.43457 11.8195 8.49025C9.86249 8.39204 7.94794 7.88366 6.19998 6.99805C4.45203 6.11244 2.90969 4.86939 1.67297 3.34947C1.0444 4.43319 0.852057 5.7156 1.13503 6.93604C1.418 8.15649 2.15506 9.2234 3.19641 9.91994C2.41463 9.89512 1.64998 9.68463 0.965625 9.30587V9.36681C0.964925 10.5041 1.3581 11.6065 2.07831 12.4867C2.79852 13.3669 3.80132 13.9705 4.91625 14.1949C4.19206 14.3931 3.43198 14.4219 2.69484 14.2793C3.00945 15.2574 3.62157 16.1128 4.44577 16.7263C5.26997 17.3397 6.26512 17.6805 7.29234 17.7012C5.54842 19.0711 3.39417 19.8141 1.17656 19.8106C0.783287 19.81 0.390399 19.7859 0 19.7384C2.25286 21.1837 4.87353 21.9513 7.55016 21.9499Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" module>
.footer {
  color: var(--text-default);
  font-size: 12px;
  line-height: 16px;
  background: #fafafa;

  @include media($to: md) {
    border-top: 1px solid #eaeaea;
    font-size: 16px;
    line-height: 29px;
    background: #f2f2f2;
  }

  &__container {
    @include media($from: md) {
      display: flex;
      justify-content: space-between;
      max-width: var(--container-width);
      padding: 37px var(--container-padding-inline);
      margin-inline: auto;
    }

    @include media($to: md) {
      display: grid;
      gap: 30px;
      padding: 30px;
      text-align: center;
    }
  }

  a {
    color: inherit;

    &:hover {
      color: var(--accent-color);
    }
  }

  &__links {
    display: flex;

    @include media($from: md) {
      margin: -10px -15px;
    }

    @include media($to: md) {
      flex-direction: column;
    }

    > * {
      @include media($from: md) {
        margin: 10px 15px;
      }

      &:not(:last-child) {
        @include media($to: md) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.socials {
  display: flex;

  @include media($to: md) {
    justify-content: center;
  }

  &__item {
    display: block;
    width: 24px;
    height: 24px;
    transition: color 0.3s;

    &:not(:last-child) {
      margin-right: 30px;

      @include media($to: md) {
        margin-right: 15px;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
</style>
